<template>
  <div class="home">
    <LuckyWheel
        ref="LuckyGrid"
        width="300px"
        height="300px"
        :blocks="blocks"
        :prizes="prizes"
        :buttons="buttons"
        @start="startCallBack"
        @end="endCallBack"
    />
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {

  },
  data(){
    return{
        index:0,
        randomData:0,
        circleUrl: '/api/public/imgs/avatar.png',
        blocks: [{ padding: '10px', background: '#869cfa' }],
        prizes: [
        { fonts: [{ text: '何英泽' }], background: '#e9e8fe' },
        { fonts: [{ text: '傅奕翔' }], background: '#b8c5f2' },
        { fonts: [{ text: '孙浙鑫' }], background: '#e9e8fe' },
        { fonts: [{ text: '许婷' }], background: '#b8c5f2' },
        { fonts: [{ text: '俞银宁' }], background: '#e9e8fe' },
        { fonts: [{ text: '傅超立' }], background: '#b8c5f2' },
        { fonts: [{ text: '杨冀东' }], background: '#e9e8fe' },
        { fonts: [{ text: '没抽到' }], background: '#b8c5f2' },
      ],
      buttons:[
        { radius: '40%', background: '#617df2' },
        { radius: '35%', background: '#afc8ff' },
        {
          radius: '30%', background: '#869cfa',
          pointer: true,
          fonts: [{ text: '开始\n抽奖', top: '-20px' }]
        },
      ]
    }
  },
  created(){

  },
  methods: {
    startCallBack () {
      this.$confirm('抽到每个人的概率是公平公正的，确定抽吗？',{
         showCancelButton:false,
         customClass:'confirm',
         type:'warning'
      }).then(()=>{
        this.$refs.LuckyGrid.play()
        // 假设接口的请求速度是5s
        setTimeout(() => {
            // 5s后拿到后端返回的中奖索引
            this.randomData = Math.floor(Math.random()*100)
            console.log(this.randomData)
            if(this.randomData>=0&&this.randomData<=60){
               this.index =0
            } else if(this.randomData>60&&this.randomData<=65){
               this.index =1
            } else if(this.randomData>65&&this.randomData<=75){
               this.index =2
            } else if(this.randomData>75&&this.randomData<=84){
               this.index =3
            } else if(this.randomData>84&&this.randomData<=88){
               this.index =4
            } else if(this.randomData>88&&this.randomData<=93){
               this.index =5
            } else if(this.randomData>93&&this.randomData<=98){
               this.index =6
            } else if(this.randomData>98&&this.randomData<=99){
               this.index =7
            }
            console.log(this.index)
            // 然后停止游戏 (缓慢停止)
            this.$refs.LuckyGrid.stop(this.index)
        }, 5000)
      })
    },
    endCallBack (prize) {
      // 当完全停止时, 触发回调函数
      console.log(prize)
    },
  }
}
</script>

<style scoped>
.home{
    position: relative;
    left: 40px;
    top:100px
}

</style>

<style>
.confirm{
    width:300px;
    position: relative;
    top:-100px;
    left:-20px
}
</style>